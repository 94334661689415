const FormField = ({ placeholder, type, value, onChange }) => {
    const textField = type === 'text';

    return (
        <div className="mb__form-field">
            {textField ? (
                <input type={type} placeholder={placeholder} value={value} onChange={onChange} />
            ) : (
               <textarea value={value} rows="10" placeholder={placeholder} onChange={onChange} /> 
            )}
        </div>
    );
};

export default FormField;