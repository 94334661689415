import { Header, ContactForm } from './modules';
import { WelcomeBlock } from './components';

function App() {
    const welcomeBlockProps = {
        heading: 'Monarc Builds is in the works!'
    };

    const contactFormProps = {
        heading: "Let's discuss your project",
        intro: [
            "While we put the finishing touches on our website, if you would like to get in touch with us about your project, please feel free to use the contact form below.",
            "We aim to respond to all queries within 48 hours."
        ],
        fieldLabels: {
            name: 'Name',
            email: 'Email',
            phoneNumber: 'Phone Number',
            subject: 'Subject',
            message: 'Message',
            submit: 'Send message'
        }
    };

    return (
        <div className="mb">
            <Header />
            <main>
                <section className="container">
                    <div className="inner-content">
                        <WelcomeBlock copy={welcomeBlockProps} />
                        <ContactForm copy={contactFormProps} />
                    </div>
                </section>
            </main>
        </div>
    );
}

export default App;
