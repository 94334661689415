import { useRef, useState } from "react";
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import { FormField, SubmitButton } from "../../components";
import 'react-toastify/dist/ReactToastify.min.css';

const ContactForm = ({ copy }) => {
    const formRef = useRef();
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userPhone, setUserPhone] = useState('');
    const [userMessage, setUserMessage] = useState('');

    const toastifySuccess = () => {
        toast('Thanks, your message has been sent!', {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,  
            draggable: false,
            className: 'submit-feedback success',
            toastId: 'notifyToast',
            theme: 'colored'
        });
    };

    const toastifyFailure = () => {
        toast.warn('Please fill in all fields', {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,  
            draggable: false,
            toastId: 'notifyToast',
            theme: 'colored'
        });
    };

    const handleSubmit = e => {
        e.preventDefault();
        
        if (userName && userEmail && userPhone && userMessage) {
            const templateParams = {
                userName,
                userEmail,
                userMessage,
                userPhone
            };

            emailjs.send(
                process.env.REACT_APP_SERVICE_ID, 
                process.env.REACT_APP_TEMPLATE_ID, 
                templateParams, 
                process.env.REACT_APP_USER_ID
            ).then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });

            setUserName('');
            setUserEmail('');
            setUserPhone('');
            setUserMessage('');
            toastifySuccess();
        } else {
            toastifyFailure();
        }
    };

    const { fieldLabels } = copy;
    const { name, email, phoneNumber, message, submit } = fieldLabels;

    return (
        <div className="mb-contact-us">
            <div className="mb-contact-us__container">
                <div className="mb-contact-us__copy">
                    <h2 className="mb-contact-us__heading">{copy.heading}</h2>
                    {copy.intro.map((para, idx) => {
                        return (
                            <p key={idx}>{para}</p>
                        )
                    })}
                </div>
                <form className="mb-contact-us__form" ref={formRef} onSubmit={handleSubmit}>
                    <FormField placeholder={name} type='text' value={userName} name="user_name" onChange={e => setUserName(e.target.value)} />
                    <FormField placeholder={email} type='text' value={userEmail} name="user_email" onChange={e => setUserEmail(e.target.value)} />
                    <FormField placeholder={phoneNumber} type='text' value={userPhone} name="user_phone" onChange={e => setUserPhone(e.target.value)} />
                    <FormField placeholder={message} type='textarea' value={userMessage} name="user_message" onChange={e => setUserMessage(e.target.value)} />
                    <SubmitButton value={submit} />
                    <ToastContainer />
                </form>
            </div>
        </div>
    );
};

export default ContactForm;