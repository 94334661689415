import { Logo } from '../../components/index';

const Header = () => {
    return (
        <header className="mb__header">
            <Logo />
        </header>
    );
};

export default Header;